import { focusWholeQuestionSearch } from '../search/focus_whole_question_search';

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-focus-whole-question-search').forEach(a => {
    a.addEventListener('click', e => {
      e.preventDefault();
      focusWholeQuestionSearch();
    });
  });
});
