export function focusWholeQuestionSearch() {
  // 見えているかどうかをisVisible()でちゃんと判定しないと、スクロール位置がおかしくなる

  // PC
  const input = document.querySelector('.js-whole-search-input');
  if (input && isVisible(input)) {
    input.focus();
  }

  //SP
  const button = document.querySelector('.js-whole-search-button');
  if (button && isVisible(button)) {
    button.click();
  }
}

function isVisible(element) {
  return (
    window.getComputedStyle(element).getPropertyValue('display') !== 'none'
  );
}
